<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Nova característica"
            type="primario"
            @click="onNew"
          />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="nome"
        :sortDesc="true"
        @click="onClick"
        search
        ref="datatable"
      >
        <template v-slot:ativo="{ col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-if="!col" color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:idcaracteristica="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButton from '@/components/form/FormButton'
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import Chip from '@/components/Chip'
import { mapGetters } from 'vuex'
import { CARACTERISTICA_PEDIDO_DELETE } from '@/store/actions/caracteristicaPedido'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'CaracteristicaPedidoTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    Chip,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: 'Nome interno', value: 'nome_interno' },
      { text: 'Exibir', value: 'ativo', align: 'center' },
      { text: 'Ordenação', value: 'ordenacao', align: 'center' },
      {
        text: 'Opções',
        value: 'idcaracteristica',
        clickable: false
      }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/caracteristica-pedido/${this.getFornecedorId}/datatable`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    onClose() {
      this.$refs.datatable.reload()
    },
    onClick(item) {
      this.$router.push(`caracteristica-pedido/${item.idcaracteristica}`)
    },
    onNew() {
      this.$router.push('caracteristica-pedido/novo')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão da característica '${item.nome}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.$store
        .dispatch(CARACTERISTICA_PEDIDO_DELETE, item.idcaracteristica)
        .then(() => {
          this.$vueOnToast.pop('success', 'Característica removida com sucesso')
          this.$refs.datatable.reload()
        })
    }
  }
}
</script>
